html {
  text-size-adjust: 100%;
}
body {
  font-family: "Sarabun", sans-serif !important;
}
.box-alert {
  margin-left: 2em;
  margin-right: 2em;
  margin-top: 20px;
  font-size: 1rem;
}

.column2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.icon-rizz {
  width: 60%;
  height: 60%;
  margin-left: -1vw;
}

.icon-max {
  width: 60%;
  height: 60%;
  margin-top: -2vw;
}
.icon-dplus {
  width: 60%;
  height: 60%;
  margin-top: 0vw;
  margin-left: -1vw;
}

/* .col {
    display: flex !important;
    justify-content: center !important;
    background-color: white;
} */

.column {
  display: flex;
  flex-direction: column;
}

.ant-input {
  /* width: 200px !important; */
  margin: 0 8px 8px 0;
}

.example {
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  width: 100vw;
  height: 100vh;
  padding: 20px;
}

@page body-print {
  size: 8.27in 11.69in;
  margin: 0.5in 0.5in 0.5in 0.5in;
  mso-header-margin: 0.5in;
  mso-footer-margin: 0.5in;
  mso-paper-source: 0;
}

.body-print {
  /* background-color:#FFFFFF;   
    border: solid 1px black ;  
    margin: 3mm */
}

@page {
  size: auto;
  margin: 0mm;
}

/* width */

/*###################### BillShippingScene ######################*/

.container-bill {
  /* height: 100vh; */
  width: 21cm;
  height: 29.6cm;
  padding: 0.2cm;
  page-break-after: always;
  position: relative;
  border: 1px solid #000;
  margin: auto;
}

.image-logo {
  width: 4cm;
  height: 3cm;
}

.content-section1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: "100%";
  /* text-align: center; */
  margin-top: 2.5cm;
  padding-left: 25%;
  padding-right: 20%;
}

.content-section1 font {
  font-size: 0.5cm;
}

.section1 {
  border-bottom: 1px solid #000;
  padding: 3mm;
  height: 50%;
}

.section1 font {
  font-size: 0.4cm;
}

.content-section2 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: "100%";
  /* text-align: center; */
  margin-top: 2cm;
  padding-left: 5%;
  padding-right: 5%;
}

.border-print {
  height: 100%;
}

@media print {
  body {
    width: 21cm;
    height: 0cm;
  }
  .border-print {
    border: 1px solid #000;
    padding: 0mm;
  }
  .section1 {
    border-bottom: 1px solid #000;
    padding: 3mm;
    height: 100%;
  }
  .container-bill {
    /* height: 100vh; */
    width: 20.5cm;
    height: 10cm;
    padding: 0.5cm;
    page-break-after: always;
    position: relative;
    border: 0px solid #000;
    margin: auto;
  }
}

thead[class*="ant-table-thead"] th {
  background-color: #eee !important;
  font-size: 0.8vw;
}

.ant-btn {
  /* min-width: 7vw !important; */
  height: 2.4vw !important;
  font-size: 1vw !important;
  margin: 3px !important;
}

.ant-input {
  /* height: 2.4vw !important; */
  font-size: 1vw !important;
}
.ant-switch-checked {
  background-color: #1890ff !important;
}
.even {
  background-color: #fbfbfb !important;
}

.row-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.row-between2 {
  align-items: flex-start !important;
}

.td-content {
  font-size: 0.8vw;
  cursor: pointer;
}

.td-content-confirm {
  padding: 0px !important;
  width: 7vw !important;
}

.ant-select-selection {
  height: 2.4vw !important;
}
.ant-select-selection__rendered {
  line-height: 42px !important;
}
.ant-table-wrapper {
  width: 100%;
  padding: 0 !important;
}

font {
  font-size: 0.8vw;
}

.table-report tr:hover td {
  background-color: inherit !important;
  opacity: 1;
}

.ant-table-body {
  margin: 0px !important;
}

.header-login {
  color: #fff;
  padding: 1vw;
}

.content-login {
  padding-right: 1vw;
  padding-left: 1vw;
  padding-top: 1.4vw;
  padding-bottom: 1.4vw;
}

.font-table {
  font-size: 80%;
  width: 3vw;
}

/*###################### Registerwarranty ######################*/
.boorder-detail {
  border: 1px solid #000;
}
.border-claim {
  border: 0.5px solid #cccccc;
  padding: 1em;
}
.column-claim-cr {
  display: flex;
  flex-direction: column;
  margin: 20px;
  margin-left: 30px;
}
.input-claim-cr {
  width: 100%;
  margin-top: 20px;
}
.global-search-wrapper {
  padding-right: 50px;
}

.global-search {
  width: 100%;
}

.global-search.ant-select-auto-complete .ant-select-selection--single {
  margin-right: -46px;
}

.global-search.ant-select-auto-complete
  .ant-input-affix-wrapper
  .ant-input:not(:last-child) {
  padding-right: 62px;
}

.global-search.ant-select-auto-complete
  .ant-input-affix-wrapper
  .ant-input-suffix
  button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.global-search-item {
  display: flex;
}

.global-search-item-desc {
  flex: auto;
  text-overflow: ellipsis;
  overflow: hidden;
}

.global-search-item-count {
  flex: none;
}
.certain-category-icon-uncolect {
  color: #a02b29;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  font-size: 16px;
}
.certain-category-icon-colect {
  color: #54b458;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  font-size: 16px;
}
.outline-focus {
  outline-color: #f92525;
}
.btn-search {
  margin-top: 50px !important;
  background-color: #fdd105 !important;
}
.btn-why {
  background-color: #fdd105 !important;
  color: "#484848";
  width: 10vw;
  height: 20vw;
}

.row-edit {
  margin-top: 1vw;
}
.icon-confirm {
  color: #faad14 !important;
  font-size: 22px;
}
.icon-success {
  color: #54b458 !important;
  font-size: 22px;
}

.icon-error {
  color: #f5222d !important;
  font-size: 22px;
}

.text-status {
  font-size: 0.8vw;
  margin-left: 0.8vw;
}

.contain {
  object-fit: contain;
}

.text-area {
  font-size: 0.8vw !important;
  height: 3.4vw !important;
}

.zoom {
  transition: transform 0.2s; /* Animation */
  margin: 0 auto;
}
.zoom:hover {
  -ms-transform: scale(2); /* IE 9 */
  -webkit-transform: scale(2); /* Safari 3-8 */
  transform: scale(2);
}
.btn-editProduct {
  border-radius: 100% !important;
  width: fit-content !important;
  height: fit-content !important;
  padding: 0.4vw 0.7vw !important;
}
.search-editProduct .ant-input-search-button {
  background-color: #40a9ff !important;
  border-color: #40a9ff !important;
  margin: 0 !important;
}

.btn-save-editProduct {
  width: 100%;
  background-color: #52c41a !important;
  color: #fff !important;
  margin: 0 !important;
  border: none !important;
}
.btn-save-editProduct:disabled {
  background-color: #f0c800 !important;
}

.btn-save-editProduct:hover:enabled {
  background-color: #4bad1a !important;
}

.modalEditProduct .ant-col-24 {
  display: flex;
  justify-content: center;
}

.modalEditProduct .ant-row:not(:last-child) {
  padding: 10px 0;
}
.modalEditProduct .ant-modal-header {
  padding: 48px 24px 24px 24px;
}
.modalEditProduct .ant-modal-body {
  padding: 0 48px 12px 48px;
}
.modalEditProduct .ant-modal-footer {
  padding: 0;
}
.modalEditProduct.ant-modal {
  width: auto !important;
  padding: 0;
}
.label-editProduct {
  color: #000;
  font-size: 0.8vw;
  font-weight: bold;
  margin: 0 0 10px -10px;
}
.title-editProduct {
  text-align: center;
  font-size: 1vw;
  font-weight: bold;
  margin: 0;
}
.img-editProduct {
  position: relative;
  margin: 20px 0 !important;
  border-radius: 10px;
  text-align: center;
}
.img-editProduct img {
  max-height: 6vw;
  border-radius: 10px;
}

.input-editProduct {
  text-align: center !important;
  color: #000 !important;
  font-size: 1vw !important;
  padding: 0 !important;
  margin: 0 !important;
}

.input-success {
  box-shadow: 0px 0px 5px #4bad1a !important;
}

.input-barcode-editProduct .ant-input,
.label-barcode-editProduct,
.editclaimProduct input {
  text-align: center !important;
  color: #000;
  font-size: 1vw !important;
  padding: 0;
  margin: 0;
  height: 100% !important;
  padding: 10px;
  letter-spacing: 0.2vw;
}
.label-barcode-editProduct {
  letter-spacing: 0.2vw;
}

.input-barcode-editProduct .ant-input-group-addon:first-child {
  background-color: rgba(204, 204, 204, 0.7);
}
.input-editProduct:disabled {
  background-color: rgba(204, 204, 204, 0.7);
}
.btn-search-editProduct {
  font-size: 1vw;
  width: 100% !important;
  background-color: #40a9ff;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
}

.input-barcode-editProduct .ant-input-group-addon:last-child {
  margin: 0 !important;
  padding: 0 !important;
  background-color: #40a9ff;
}
.late {
  background-color: rgba(240, 200, 0, 0.15);
}
.ant-table-body {
  overflow-x: auto;
  font-size: 0.8vw;
}
.column-claim-cr .ant-select-selection--single {
  height: 2.4vw !important;
  font-size: 1vw !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
/* Chrome, Safari, Edge, Opera */
.input-number input::-webkit-outer-spin-button,
.input-number input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
.input-number input[type="number"] {
  -moz-appearance: textfield;
}
.ant-form-item {
  margin-bottom: 10px !important;
}
.ant-input-group-addon {
  padding: 0 0 !important;
}
.editclaimProduct {
  display: flex;
  align-items: center;
}
.pd-hz-10 {
  padding: 0 10px;
}
.pd-vt-10 {
  padding: 10px 0;
}
.pd-10 {
  padding: 10px;
}
.rot-y-180 {
  transform: rotateY(180deg);
}
td,
th,
.ant-table-placeholder {
  border: 1px solid #ccc !important;
}
.label-upload {
  position: relative;
  width: 260px;
  height: 260px;
  display: block;
  background-color: #ddd;
  border-radius: 10px;
  border: 1px dashed #aaa;
  cursor: pointer;
  overflow: hidden;
}
.label-upload img {
  width: 300px;
  height: 300px;
}
.label-upload:hover {
  border: 1px dashed #000;
}
.label-upload .span-upload {
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0px 50px;
  border-radius: 20px 20px 0 0;
  color: #fff;
  font-weight: bold;
}
.label-upload input {
  display: none !important;
}
.form-claim *:disabled {
  color: #000;
}
/* .alert-editModal {
    margin-top: 20px !important;
    font-size: .8vw !important;
    display: flex !important;
}
.alert-editModal .ant-alert-icon {
    position: relative !important;
    margin-right: 1vw !important;
} */
.antSelect .ant-select-selection {
  display: flex !important;
  align-items: center !important;
  height: 2vw !important;
}
.ant-alert-icon {
  top: 50% !important;
  transform: translateY(-50%) !important;
}
.ant-avatar-string {
  transform: translateX(-50%);
}
.formLabel label,
.formLabelAddress label {
  font-size: 0.8vw !important;
}
.formLabelAddress input {
  font-size: 0.8vw !important;
  height: auto !important;
}
.formLabel .ant-select-selection {
  overflow: hidden;
}
/* .siderMenu {
    width: 12vw !important;
    max-width: 12vw !important;
    min-width: 12vw !important;
    flex: 0 0 12vw !important;
}
.siderMenu .anticon, .siderMenu span {
    font-size: 0.8vw !important;
}
.siderMenu .ant-menu-item:not(:last-child) {
    margin: .45vw 0 !important;
} */
.cr-mg-5 {
  margin: 5px 5px 5px 30px !important;
}
.ant-modal-close-x {
  font-size: 0.8vw !important;
}
.updateEms:hover {
  text-decoration: underline;
}
